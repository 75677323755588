
/*--designed menu latteral--*/
.toogle{
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 10;
  right: 9%;
  top: 0;
  transform: translate(-5px, 49px);
}
.logo{
  width: 130px;
}
.icon{
  display: none;
}
.description .lang{
  transform: translate(-40px, -12px); 
  color: #3b3a3a;
}
ul li a{
  height: 35px;
 color: white;
 /* text-decoration: non; */
}
#liste{
  width: 100%;
  height: 100vh;
  background: #cc1616;
  padding: 10px;
  list-style-type: none;
  margin-top: 30px;
}
.see{
  position: fixed;
  width: 20%;
  top: 20vh;
  left: 0;
  border-right: 1px solid #000;
  box-sizing: initial;
  transition: 0.5s;
  z-index: 10px;
}
.description {
  position: relative;
  box-sizing: border-box;
  min-height: 150vh;
}
/* .show{
  left: 100%!important;
} */
.description-menu {
  position: fixed;
  width: 20%;
  top: 20vh;
  left: -110%;
  border-right: 1px solid #000;
  box-sizing: initial;
  transition: width 0.5s;
  z-index: 10px;
}
.description .help-Gone{
      /* width: 90%; */
      display: flex;
      justify-content: space-between;
      color: white;
      font-size: 15px;
      transform: translate(0px,15px);
}
.description-menu .list-ul {
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 10px;
}
.description-menu ul .list {
  position: relative;
  width: 100%;
  overflow: hidden;
}
#liste .list{
  margin: 30px 0;
  padding: 10px 10px 10px 4%;
  font-size: 21px;
  font-weight: bold;
}
.description-menu ul .list:active {
    background-color: rgb(219, 217, 217);
    border-left: 3px solid var(--red);
    /* border-right: 2px solid var(--red); */
    color: var(--red);
}
.description-menu ul .list.active a {
  color: var(--black);
}

.description-menu ul .list a {
  display: block;
  width: 100%;
  color: #636262;
  text-decoration: none;
}
.description-menu .title {
  display: none;
}
.description-menu ul .list a i {
  position: relative;
  display: inline-block;
  min-width: 60px;
  height: 60px;
  line-height: 70px;
  text-align: center;
  color: #000;
}
.description-menu ul .list a title {
  position: relative;
  display: block;
  padding-left: 10px;
  height: 60px;
  line-height: 60px;
  white-space: normal;
}
.description .help-rt{
  text-align: left;
    font-family: 'Segoe UI';
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    float: right;
    margin-top: 2px;
    margin-left: 7px!important;
}

/*--designed rigth content--*/

/* header css */

.description .help-bd { 
  position: fixed;
  height: 25vh;
  width: 100%;
  background: #CC1616 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 22px;
  top: 0;
}
.description-body{
  padding: 0 0px 0 15px;
}
/*---styling about---*/
#dsc-about{
  padding: 215px 0px 0 0;
}
#dsc-about h2.title2{
    text-align: center!important; 
}
#dsc-about p.title3{
  font-size: 22px;
  text-align: left;
}
#dsc-about p.title4{
  padding-left: 5%;
  text-align: left;
}
#dsc-about .player{
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
.description .player5{
  width: 100%;
}

/*----styling move----*/
#dsc-move{
  padding: 185px 15px 0 0;
}
#dsc-move .player{
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-move .player2 .description-img1{
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-move h2.title2{
  text-align: center!important; 
  margin: 15px;
}
#dsc-move p.title3{
  font-size: 22px;
  text-align: left;
}
#dsc-move p.title4{
  padding-left: 5%;
  padding-right: 5px;
  text-align: left;
}
#dsc-move .player2{
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-move .player2 .description-img1 {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-move .player3{
  width: 35%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}

/*---styling  buy----*/
#dsc-buy{
  padding: 0px 10px 0 0;
}
#dsc-buy .player2 .description-img1{
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-buy h2.title2{
  text-align: center!important; 
  margin: 15px;
  padding-top: 50px;
}
#dsc-buy p.title3{
  font-size: 22px;
  text-align: left;
}
#dsc-buy p.title4{
  padding-left: 5%;
  padding-right: 5px;
  text-align: left;
}
#dsc-buy .player2{
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-buy .player2 .description-img1 {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-buy .player3{
  width: 35%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-buy .player{
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}

/*-----styling to do-----*/
#dsc-do{
  padding: 105px 15px 0 0;
}
  
#dsc-do .player2 .description-img1{
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-do h2.title2{
  text-align: center!important; 
  margin: 15px;
}
#dsc-do p.title3{
  font-size: 22px;
  text-align: left;
}
#dsc-do p.title4{
  padding-left: 5%;
  padding-right: 5px;
  text-align: left;
}
#dsc-do .player2{
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-do .player2 .description-img1 {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-do .player3{
  width: 35%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-do .player{
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}

/*----styling to--connect-----*/
#dsc-connect{
  padding-top: 110px;
}
#dsc-connect.player2 .description-img1{
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-connect h2.title2{
  text-align: center!important; 
  margin: 15px;
}
#dsc-connect p.title3{
  font-size: 22px;
  text-align: left;
}
#dsc-connect p.title4{
  padding-left: 5%;
  padding-right: 5px;
  text-align: left;
}
#dsc-connect .player2{
  width: 75%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-connect .player2 .description-img1 {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-connect .player3{
  width: 35%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}
#dsc-connect .player{
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: center;
}

  #dsc-connect .player3{
    width: 20%;
    display: flex;
    justify-content: center;
    margin: 30px auto;
    align-items: center;
  }
  #dsc-buy .player3{
    width: 20%;
    display: flex;
    justify-content: center;
    margin: 30px auto;
    align-items: center;
  }
  #dsc-do .player3{
    width: 20%;
    display: flex;
    justify-content: center;
    margin: 30px auto;
    align-items: center;
  }
  #dsc-move .player3{
    width: 20%;
    display: flex;
    justify-content: center;
    margin: 30px auto;
    align-items: center;
  }
  #dsc-move .description-img3{
    
    margin: 7px auto;
    width: 30%;
  }
  #dsc-move .player4{
    padding : 8px auto 8px auto;
    margin: 0 auto;
  }
  .description .title-color{
    display: block;
    text-align: center;
    font-weight: bolder;
  }

  /*--new style --*/
  .hel{
    display: block;
    margin-top: 10px;
  }
  .help-one{
    display: block;
    margin-left: 5%;
    width: 100%;
    margin-top: 40px;
    color: white!important;
    font-size: 1.5em;
    text-decoration: none;
  }
  .help-one > * {
    margin:0 0 0 10px;
    width: 35%;
  }
  .help-two{
    margin: 10px auto;
    text-align: center;
    font-size: 0.8em;
    color: white!important;
  }
  .help-two > *{
    margin: 5px auto;
    text-align: left;
    padding: 0 0 0px 30px;
  
  }
  @media only screen and (min-width:992px){
    .logo{
      width: 170px;
    }
    .title2{
      padding-top: 15px;
    }
    #liste .list{
      font-size: 18px;
    }
    #liste{
      width: inherit;
      background: inherit;
      padding: 0;
      list-style-type: none;
      margin-top: inherit;
    }
    .icon{
      display: inline-flex;
    }
    #liste .list{
      margin: 15px 0;
      padding: 10px;
      width: 85%;
      font-weight: inherit;
    }
    .toogle{
      display: none;
    }
    .description .lang{
      transform: translate(20%, -25px);
    }
    
    .description-menu {
      left: 0;
      position: fixed;
    }
    #dsc-about{
      padding: 75px 20px 
    }
    #dsc-move{
      padding: 105px 15px 0 0;
    }
    #dsc-buy{
      padding: 105px 15px 0 0;
    }
    .description .description-body .description-content {
      margin: 0px 20px 10px 23%;
    }
    .description .help-bd { 
      position: fixed;
      width: 99.5vw;
      height: 8vh;
      padding:20px 0 10px 0;
      width: 100%;
      background: #CC1616 0% 0% no-repeat padding-box;
      opacity: 1;
      display: flex;flex-direction: column;
      justify-content:flex-start;align-items: center;
      font-size: 22px;
      top: 0;
    }
    .description .help-Gone{
      width: 70%;
      padding-top: 0;
      font-size: inherit;
    }
    .description .help-gone .hlp-content{
      margin-top: 50px;
    }
    .description .help-gone .hlp-content1{
      margin-top: 20px;
    }
    .description p.title3{
      font-size: 22px;
      text-align: center;
    }
    .description .help-logo{
      margin-right: 30%!important;
    }
    .description .title4,li{
      font-size: 20px;
    }
    .description-menu .list-ul {
      padding-top: 45px;
      padding-left: 15px;
    }
   
    .description .player7{
      display: flex;
      justify-content: center;
      margin: 30px auto;
      align-items: center;
      width: initial;
    }
    .description .player8{
      width: initial;
    }
    .description-menu ul .list {
      position: relative;
      width: 93%;
      /* border-top-left-radius: 15px;
      border-bottom-left-radius: 15px; */
      overflow: hidden;
    }
    .description-menu ul .list:hover {
      /* border-right: 2px solid var(--red);   */
      background-color: var(--transparent);
      border-left: 3px solid var(--red);
      /* color: var(--red); */
    }
    .description-menu .title{
      display: flex;
      align-items: center;
      /* color: #0f394D; */
    }
    .description-menu ul .list a i {
      position: relative;
      display: flex;
      align-items: flex-start;
      min-width: 60px;
      height: 60px;
      line-height: 70px;
      text-align: center;
  }
    .description-menu ul .list a {
     display: flex;
  }
    .description-menu ul .list:hover a {
     display: flex;
     color: #CC1616;
  }
    .description-menu ul .list:focus a {
     display: flex;
     color: #CC1616;
  }

  /*---new style--*/
  .hel{
    display: flex;
    margin-top: -15px;
    justify-content: space-between;
    width: 70%;
    /* background-color: black; */
  }
  .help-one{
    display: block;
    margin-left: 5%;
    padding-top: 15px;
    width: 100%;
    margin-top: 0;
    color: white!important;
    font-size: 1.5em;
  }
  .help-one > * {
    margin:0;
  }
  .help-two{
    text-align: center;
    font-size: 0.8em;
    color: white!important;
  }
  .help-two > *{
    width: 100%;
    margin-top: 15px;
  }
    
  }
