/*-- designed by will-turner copyright-2021 --*/

.container{
    text-align: center;
}
input:focus,input:active{
    outline: none;
}
.svg1{
    width: 50px;height: 50px;
    fill: hsl(0, 0%, 100%);
}
.search_content{
    width: 100%;
}
.help_bd form{
    width: 100%;
}
#form{
    width: 71%;
}
.helpSearch:focus,.helpSearch:active,.helpSearch:any-link{
    color: white;opacity: 1;background: #fa5454;
}
.img-search{
    top: 210px;left: 16%;
    width: 30px;height: 30px;
}
.help-video{
    display: flex;
    width: 100%;
    height: auto;margin-left: auto;margin-right: auto;
    justify-content: center;
    pointer-events: visible;
}


@media only screen and (min-width:992px)
{   
    .helpList{ 
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 20px;
    }
    
     .svg1{
        width: 30px;height: 30px;margin-top: 5px;margin-right: 10px;
    }
    .wallete,.contacte{
        width: 95px;height: 95px;
    }
   
    
}


/*---site mode desktop---*/
@media only screen and (max-width:992px)
{
    .helpList{
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;margin-bottom: 20px;
    }
   
    .svg1{
        width: 50px;height: 40px;fill: white;margin-top: 5px;margin-right: 10px;
    }
    h3{
        text-align: center;
    }
    .wallete,.contacte{
        width: 75px;height: 75px;margin-top: 15px;
        margin-left: 45%;
    }
    .img-search{
        top: 185px;left: 16%;
        width: 30px;height: 30px;
    }
}


/*---site mode tablette--*/
@media only screen and (max-width:778px)
{
    .helpList{
        width: 100%;
        margin-bottom: 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
    }
    .svg1{
        width: 40px;height: 40px;fill: white;margin-top: 5px;margin-right: 10px;
    }
    h3{
        text-align: center;
    }
    .wallete,.contacte{
        width: 75px;height: 75px;margin-top: 15px;
        margin-left: 40%;
    }
    .img-search{
        top: 190px;left: 16%;
        width: 30px;height: 30px;
    } 
}





/*-- site mode telphone --*/
@media only screen and (max-width:576px)
{   
    
    .helpList{
        width: 100%;
        padding-bottom: 15px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
    }
    .svg1{
        width: 40px;height: 40px;fill: white;margin-top: 5px;margin-right: 10px;
    }
    h3{
        text-align: center;
    }
    .wallete,.contacte{
        width: 75px;height: 75px;margin-top: 15px;margin-left: 40%;
    }
    .img-search{
        top: 158px;left: 16%;
        width: 20px;height: 20px;
    }
} 
 






