:root {
    --no-value: none;
    --white: #fff;
    --berge: #f6efef;
    --red: #cc1616;
    --red-transparent: #cc16160a;
    --blue: blue;
    --green: #2ecc71;
    --night-blue: #0f394c;
    --transparent: #cc16160a;
    --transparent2: #a6a8a8;
    --white-gray: #f9f9f9;
    --gray: #707070;
    --black: #000000;
    --transparency: transparent;
    --police: 'Vollkorn', serif;
    --police2: 'Merienda', cursive;
  }
  
  html,
  body,
  header,
  main,
  footer,
  ul {
    margin: 0px;
    padding: 0px;
  }