.lang {
  /* border: 1px solid aquamarine; */
  position: absolute;
  padding: 5px;
  right: 9.5%;
  top: 50px;
  outline: none;
  text-transform: uppercase;
  z-index: 10;
  transform: translate(10px, -30px);  
}
.lang:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.lang .lang-item {
  display: flex;
  align-items: center;
  font-size: 1.4em;
  font-weight: 500;
}
.lang img {
  display: block;
  width: 30px;
  margin-right: 5px;
}
.langs {
  display: none;
  position: absolute;
  left: 0;
  top: 34px;
  background-color: transparent;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
  rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 5px;
  z-index: 10;
}
.langs.show {
    display: block;
    z-index: 15;
}
.langs .lang-item {
  margin-top: 10px;
}
@media screen and (min-width: 768px)  {
  .lang {
    transform: translatex(0px);
  }
    .lang:hover {
        cursor: pointer;
    }
    /* .lang {
      transform: translate(-10px, 30px);
    } */
}
